function Loader() {
  // console.log("RENDER LOADING");
  let styleee = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: "#000000f5",
    scrollSnapAlign: "end",
  };

  return (
    <div style={styleee}>
      <svg
        version="1.1"
        id="L4"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        width="50px"
        height="50px"
      >
        <circle fill="#fff" stroke="none" cx="10" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.1"
          />
        </circle>
        <circle fill="#fff" stroke="none" cx="45" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.2"
          />
        </circle>
        <circle fill="#fff" stroke="none" cx="80" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.3"
          />
        </circle>
      </svg>
    </div>
  );
}

export default Loader;
