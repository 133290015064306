// import homeImg2 from "../asserts/images/DSC_0806.jpg";
import imgEx from "../asserts/images/example.jpg";

import archImg1 from "../asserts/images/arch/DSC_9882.jpg";
import archImg2 from "../asserts/images/arch/DSC_9826.jpg";
import archImg3 from "../asserts/images/arch/DSC_3050.jpg";
import archImg4 from "../asserts/images/arch/DSC_3054.jpg";

import candidImg1 from "../asserts/images/candid/DSC_2737.jpg";
import candidImg3 from "../asserts/images/candid/DSC_2775.jpg";
import candidImg5 from "../asserts/images/candid/DSC_2790.jpg";
import candidImg2 from "../asserts/images/candid/DSC_2987.jpg";
import candidImg4 from "../asserts/images/candid/DSC_2988.jpg";
import candidImg6 from "../asserts/images/candid/DSC_3144.jpg";

import stImg1 from "../asserts/images/street/DSC_0353.jpg";
import stImg2 from "../asserts/images/street/DSC_0050.jpg";
import stImg3 from "../asserts/images/street/DSC_8182.jpg";
import stImg4 from "../asserts/images/street/SID_8842.jpg";

import fasImg1 from "../asserts/images/fashion/SID_6806.jpg";
import fasImg2 from "../asserts/images/fashion/SID_5838.jpg";
import fasImg3 from "../asserts/images/fashion/SID_5826.jpg";

import foodImg1 from "../asserts/images/food/SID_7064.jpg";

import landImg1 from "../asserts/images/landscape/DSC_0523.jpg";
import landImg2 from "../asserts/images/landscape/DSC_1039.jpg";
import landImg3 from "../asserts/images/landscape/DSC_2278.jpg";
import landImg4 from "../asserts/images/landscape/DSC_0686.jpg";
import landImg5 from "../asserts/images/landscape/DSC_4373.jpg";

import prodImg1 from "../asserts/images/product/DSC_1679.jpg";
import prodImg2 from "../asserts/images/product/DSC_2398.jpg";
import prodImg3 from "../asserts/images/product/DSC_1702.jpg";
import prodImg4 from "../asserts/images/product/DSC_2516.jpg";
import prodImg5 from "../asserts/images/product/DSC_2502.jpg";
// import prodImg6 from "../asserts/images/product/DSC_2613.jpg";
// import prodImg7 from "../asserts/images/product/DSC_2443.jpg";
import prodImg8 from "../asserts/images/product/DSC_1739.jpg";
import prodImg9 from "../asserts/images/product/DSC_2547.jpg";
import prodImg10 from "../asserts/images/product/DSC_1699.jpg";
import prodImg11 from "../asserts/images/product/YYN_5875.jpg";

import portImg1 from "../asserts/images/portraits/YYN_8107.jpg";
import portImg2 from "../asserts/images/portraits/YYN_8140.jpg";
import portImg3 from "../asserts/images/portraits/YYN_8147.jpg";
import portImg4 from "../asserts/images/portraits/YYN_8154.jpg";
import portImg5 from "../asserts/images/portraits/YYN_8158.jpg";
import portImg6 from "../asserts/images/portraits/YYN_8162.jpg";

import series_covid_Img1 from "../asserts/images/series/covid/MDF1.jpg";
import series_covid_Img2 from "../asserts/images/series/covid/MDF2.jpg";
import series_covid_Img3 from "../asserts/images/series/covid/MDF3.jpg";
import series_covid_Img4 from "../asserts/images/series/covid/MDF4.jpg";
import series_covid_Img5 from "../asserts/images/series/covid/MDF5.jpg";
import series_covid_Img6 from "../asserts/images/series/covid/MDF6.jpg";
import series_covid_Img7 from "../asserts/images/series/covid/MDF7.jpg";
import series_covid_Img8 from "../asserts/images/series/covid/MDF8.jpg";
import series_covid_Img9 from "../asserts/images/series/covid/MDF9.jpg";
import series_covid_Img10 from "../asserts/images/series/covid/MDF10.jpg";
import series_covid_Img11 from "../asserts/images/series/covid/MDF11.jpg";

import series_dog_Img1 from "../asserts/images/series/dog/DSC_0461.jpg";
import series_dog_Img2 from "../asserts/images/series/dog/DSC_7860.jpg";
import series_dog_Img3 from "../asserts/images/series/dog/DSC_8855.jpg";
import series_dog_Img4 from "../asserts/images/series/dog/DSC_9373.jpg";
import series_dog_Img5 from "../asserts/images/series/dog/DSC_9615.jpg";
import series_dog_Img6 from "../asserts/images/series/dog/SID_7308.jpg";

let series_dog_Imgs = [
  { img: series_dog_Img1, imgclass: "carousel-img", caption: "" },
  { img: series_dog_Img2, imgclass: "carousel-img", caption: "" },
  { img: series_dog_Img3, imgclass: "carousel-img", caption: "" },
  { img: series_dog_Img4, imgclass: "carousel-img", caption: "" },
  { img: series_dog_Img5, imgclass: "carousel-img", caption: "" },
  { img: series_dog_Img6, imgclass: "carousel-img", caption: "" },
];
let series_covid_Imgs = [
  { img: series_covid_Img1, imgclass: "carousel-img", caption: "" },
  { img: series_covid_Img2, imgclass: "carousel-img", caption: "" },
  { img: series_covid_Img3, imgclass: "carousel-img", caption: "" },
  { img: series_covid_Img4, imgclass: "carousel-img", caption: "" },
  { img: series_covid_Img5, imgclass: "carousel-img", caption: "" },
  { img: series_covid_Img6, imgclass: "carousel-img", caption: "" },
  { img: series_covid_Img7, imgclass: "carousel-img", caption: "" },
  { img: series_covid_Img8, imgclass: "carousel-img", caption: "" },
  { img: series_covid_Img9, imgclass: "carousel-img", caption: "" },
  { img: series_covid_Img10, imgclass: "carousel-img", caption: "" },
  { img: series_covid_Img11, imgclass: "carousel-img", caption: "" },
];

let portImgs = [
  { img: portImg1, imgclass: "carousel-img", caption: "" },
  { img: portImg2, imgclass: "carousel-img", caption: "" },
  { img: portImg3, imgclass: "carousel-img", caption: "" },
  { img: portImg4, imgclass: "carousel-img", caption: "" },
  { img: portImg5, imgclass: "carousel-img", caption: "" },
  { img: portImg6, imgclass: "carousel-img", caption: "" },
];

let candidImgs = [
  { img: candidImg1, imgclass: "carousel-img", caption: "" },
  { img: candidImg2, imgclass: "carousel-img", caption: "" },
  { img: candidImg3, imgclass: "carousel-img", caption: "" },
  { img: candidImg4, imgclass: "carousel-img", caption: "" },
  { img: candidImg5, imgclass: "carousel-img", caption: "" },
  { img: candidImg6, imgclass: "carousel-img", caption: "" },
];

let prodImgs = [
  { img: prodImg1, imgclass: "carousel-img", caption: "" },
  { img: prodImg2, imgclass: "carousel-img", caption: "" },
  { img: prodImg3, imgclass: "carousel-img", caption: "" },
  { img: prodImg4, imgclass: "carousel-img", caption: "" },
  { img: prodImg5, imgclass: "carousel-img", caption: "" },
  { img: prodImg8, imgclass: "carousel-img", caption: "" },
  { img: prodImg9, imgclass: "carousel-img", caption: "" },
  { img: prodImg10, imgclass: "carousel-img", caption: "" },
  { img: prodImg11, imgclass: "carousel-img", caption: "" },
];
// { img: prodImg6, imgclass: "carousel-img", caption: "" },
// { img: prodImg7, imgclass: "carousel-img", caption: "" },

let streetImgs = [
  { img: stImg1, imgclass: "carousel-img", caption: "" },
  { img: stImg2, imgclass: "carousel-img", caption: "" },
  { img: stImg3, imgclass: "carousel-img", caption: "Never waste an embrace." },
  {
    img: stImg4,
    imgclass: "carousel-img",
    caption: "True care is shown through the littlest of things",
  },
];

let fashionImgs = [
  { img: fasImg1, imgclass: "carousel-img", caption: "" },
  { img: fasImg2, imgclass: "carousel-img", caption: "" },
  { img: fasImg3, imgclass: "carousel-img", caption: "" },
];

let foodImgs = [
  {
    img: foodImg1,
    imgclass: "carousel-img",
    caption: "An appetite for food, as well as art",
  },
];

let archImgs = [
  { img: archImg1, imgclass: "carousel-img", caption: "Marvel Marble" },
  { img: archImg2, imgclass: "carousel-img", caption: "Antique View" },
  { img: archImg3, imgclass: "carousel-img", caption: "" },
  { img: archImg4, imgclass: "carousel-img", caption: "" },
];

let landsImgs = [
  {
    img: landImg1,
    imgclass: "carousel-img",
    caption: "Spiralling against the infinite backdrop",
  },
  { img: landImg2, imgclass: "carousel-img", caption: "Reverse Perspective" },
  { img: landImg3, imgclass: "carousel-img", caption: "" },
  { img: landImg4, imgclass: "carousel-img", caption: "Surreal" },
  { img: landImg5, imgclass: "carousel-img", caption: "Distorted Reflections" },
];

let allImgsArray = [
  landsImgs,
  prodImgs,
  streetImgs,
  series_covid_Imgs,
  series_dog_Imgs,
  fashionImgs,
  foodImgs,
  archImgs,
];

let allImgsCountArray = new Array(allImgsArray.length).fill(0);

let maxLen = 0;
for (let i = 0; i < allImgsArray.length; ++i) {
  maxLen = Math.max(maxLen, allImgsArray[i].length);
}
// console.log("maxLen", maxLen);

let homeImgs = [];
for (let i = 0; i < maxLen; ++i) {
  for (let j = 0; j < allImgsArray.length; ++j) {
    let count = allImgsCountArray[j];
    homeImgs.push(allImgsArray[j][count]);

    //increment
    allImgsCountArray[j] += 1;
    if (allImgsCountArray[j] >= allImgsArray[j].length)
      allImgsCountArray[j] = 0;
  }
}

export default function getCarouselArray(cat) {
  if (cat === "Home") {
    return homeImgs;
  } else if (cat === "Candids") {
    return candidImgs;
  } else if (cat === "Portraits") {
    return portImgs;
  } else if (cat === "Street & Documentary") {
    return streetImgs;
  } else if (cat === "Fashion") {
    return fashionImgs;
  } else if (cat === "Food Photography") {
    return foodImgs;
  } else if (cat === "Interior & Architecture") {
    return archImgs;
  } else if (cat === "Landscapes") {
    return landsImgs;
  } else if (cat === "Product") {
    return prodImgs;
  } else if (cat === "Photo Stories/Series") {
    return series_dog_Imgs;
  } else if (cat === "Million Daughter's Foundation, Covid and beyond") {
    return series_covid_Imgs;
  } else if (cat === "Murphy") {
    return series_dog_Imgs;
  } else return [{ img: imgEx, imgclass: "carousel-img" }];
}
