import Nav from "./views/Nav";
import Categories from "./views/Categories";
import getCarouselArray from "./views/carouselData";
import Footer from "./components/Footer";
import Contact from "./views/Contact";
import Loader from "./components/Loader";
import { useState, useEffect } from "react";
import React, { lazy, Suspense } from "react";
import waImg from "./asserts/images/wa.svg";
import Series from "./views/Series";
import About from "./views/About";

// import Carousel from "./views/Carousel";

const Carousel = lazy(() => import("./views/Carousel"));
// const Carousel = lazy(() => {
//   return new Promise((resolve) => {
//     setTimeout(() => resolve(import("./views/Carousel")), 300000);
//   });
// });

function App() {
  let categories = [
    {
      name: "Product",
      info: "The eyes witness a diverse range of landscapes and emotions every day. Photography not only captures those simple, mundane moments beautifully but also helps every viewer weave a unique story out of the same image. This page is a result of the love and excitement Sidhartha shares for photography as a passion with everyone who believes that pictures capture more than what a thousand words could ever contain. This space welcomes everyone who has an eye for the aesthetics of life and wishes to appreciate this personal curation over a cup of coffee, or otherwise. ",
      img: getCarouselArray("Product"),
    },
    {
      name: "Street & Documentary",
      info: "This section is a hub of visual stories built out of the streets we walk through every day, the little things we often might overlook on the way, and the range of moods of the same everyday corners - sometimes loud and vibrant, sometimes gloomy and still. Streets not only depict the freedom to choose our way, but also give us the liberty to witness a story of our choice.",
      img: getCarouselArray("Street & Documentary"),
    },
    {
      name: "Fashion",
      info: "Fashion deserves a lens that keeps us updated at all times. This section is a showcase of how fashion is not just a trend, it is a lifestyle and reflection of people and their personalities.",
      img: getCarouselArray("Fashion"),
    },
    {
      name: "Landscapes",
      info: "Capturing landscapes is a matter of not just the vision but also the little technicalities of photography. It is raw and natural and encompasses everything beautiful and ugly about scenery at the same time.",
      img: getCarouselArray("Landscapes"),
    },
    {
      name: "Photo Stories/Series",
      info: "Photo Series is one of the lengthiest and most meticulous assignments of photography. With the overload of visual content, we are subjected to nowadays, having a series of pictures that flow as a story is refreshing. It is challenging for a photographer in many ways and gives good scope to experiment. This section is a culmination of these journeys through pictures.",
      img: getCarouselArray("Photo Stories/Series"),
    },
    {
      name: "Food Photography",
      info: "Humans have progressed in more ways than one. Today, it is not just food for hunger but also food for thought. Check out this space for some mouthwatering, delicious food items on your plate moments before you finish all of it.",
      img: getCarouselArray("Food Photography"),
    },
    {
      name: "Interior & Architecture",
      info: "Design is the rule of nature. Nature is the biggest muse. The camera is the best tool to capture the grandeur and intricacy of spaces being created into your offices, homes, favourite rooms and so much more. Go and check it out.",
      img: getCarouselArray("Interior & Architecture"),
    },
    {
      name: "Candids",
      info: "Spontaneity is the rule of nature. To capture those actions, emotions and expressions that would otherwise be lost within space and time is the dominant theme for this section.",
      img: getCarouselArray("Candids"),
    },
  ];

  // {
  //   name: "Portraits",
  //   info: "Portraits.",
  //   img: getCarouselArray("Portraits"),
  // },

  let categoriesSeries = [
    {
      name: "Murphy",
      info: "",
      view: "Murphy",
    },
    {
      name: "Million Daughter's Foundation, Covid and beyond",
      info: "2021, The world was hit by second covid wave, the beautiful mountains of uttrakhand that were out of the shadow of Covid's first wave were now witnessing it's wrath. Even villages were not spared. Situation was out of hands, the poor medical facilities in the hills were exposed. In such times, @milliondaughtersfoundation took the initiative of helping covid effected villages and families. Being a NGO which works in the field of women empowerment in the hills, the foundation went out of the way and provided help to suffering families of several villages. Ration and sanitization kits were provided to the families, Medical aids were provided to respective PHC's and CHC's of two districts, Pauri Garhwal and Tehri. The foundation emphasised on Pauri Garhwal as it was near yet far to the state capital.\n\nToday, the foundation is continuing it's purpose, educating women of rural mountains about their menstrual hygiene, and providing sanitary pads.\n\n Hope this goes on and on.",
      view: "Million Daughter's Foundation, Covid and beyond",
    },
  ];
  // let categories = [
  //   { name: "Product", info: "", img: prodImg1 },
  //   { name: "Street & Documentary", info: "", img: stImg1 },
  //   { name: "Fashion", info: "", img: fasImg1 },
  //   { name: "Landscapes", info: "", img: landImg1 },
  //   { name: "Photo Stories/Series", info: "", img: imgEx },
  //   { name: "Food Photography", info: "", img: foodImg1 },
  //   { name: "Interior & Architecture", info: "", img: homeImg6 },
  // ];

  let categoriesTemp = categories.filter((e) => {
    if (e.name !== "Portraits") return true;
    else return false;
  });

  const [currentView, setCurrentView] = useState("Home");

  let carouselImages = getCarouselArray(currentView);

  useEffect(() => {
    //scroll to top
    // window.scroll({ top: 0, left: 0, behavior: "smooth" });
    document
      .querySelector("#root")
      .scroll({ top: 0, left: 0, behavior: "smooth" });
  }, [currentView]);

  console.log(currentView);
  return (
    <>
      <Nav
        categories={categories}
        currentView={currentView}
        setCurrentView={setCurrentView}
      />
      <a href="https://wa.me/919456559818" id="wa">
        <img src={waImg} alt="wa" />
      </a>
      {/* <p className="under">Website Under Development</p> */}
      {currentView === "Photo Stories/Series" ? (
        <Series setCurrentView={setCurrentView} />
      ) : (
        <Suspense fallback={<Loader />}>
          <Carousel
            currentView={currentView}
            categories={[...categories, ...categoriesSeries]}
            carouselImages={carouselImages}
          />
        </Suspense>
      )}
      <About carouselImages={getCarouselArray("Landscapes")} />
      {currentView === "Home" ? (
        <Categories
          categories={categoriesTemp}
          setCurrentView={setCurrentView}
        />
      ) : null}
      <Contact />
      <Footer />
    </>
  );
}

export default App;
