import { useState, useEffect } from "react";
import logoImg from "../asserts/images/logo-sm.jpg";

export default function Nav(props) {
  console.log("NAV RENDER");
  const { currentView, setCurrentView } = props;
  let categories = props.categories;

  //-1 first time, 0 hide, 1 show
  const [showHamMenu, setShowHamMenu] = useState(-1);

  /* ---------------------- scroll width and resize width --------------------- */
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });
  // console.log(dimensions);
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
    });
    // console.log(dimensions);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);
  let scrollWidth =
    document.querySelector("#root").offsetWidth -
    document.querySelector("#root").clientWidth;
  // console.log(scrollWidth);
  useEffect(() => {
    console.log("change width");
    // let scrollWidth =
    //   document.querySelector("#root").offsetWidth -
    //   document.querySelector("#root").clientWidth;
    // console.log(scrollWidth);
  }, [dimensions.width]);

  /* ------------------------------------ - ----------------------------------- */
  useEffect(() => {
    //to close ham menu when user click on any nav section
    setShowHamMenu(0);
  }, [currentView]);

  return (
    // <nav style={{ width: `calc(100% - ${scrollWidth}px)` }}>
    <nav style={{ paddingRight: `calc(${scrollWidth}px)` }}>
      <div className="nav-logo-title-container">
        <img
          onClick={() => setCurrentView("Home")}
          className="nav-logo"
          src={logoImg}
          alt="logo"
        />
        <div onClick={() => setCurrentView("Home")} className="nav-title">
          Yin Yang Photography
        </div>
        <div
          className={
            showHamMenu === 1 ? "hamburger ham-close-btn" : "hamburger"
          }
          onClick={() =>
            showHamMenu === 1 ? setShowHamMenu(0) : setShowHamMenu(1)
          }
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div
        className={
          showHamMenu === -1
            ? "nav-li-container"
            : showHamMenu === 1
            ? "nav-li-container show-menu"
            : "nav-li-container close-menu"
        }
      >
        {categories.map((cat, index) => {
          return (
            <li
              key={index}
              className={currentView === cat.name ? "active" : null}
              onClick={() => {
                setShowHamMenu(0);
                setCurrentView(cat.name);
              }}
            >
              {cat.name}
            </li>
          );
        })}
      </div>
    </nav>
  );
}
