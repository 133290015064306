import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import backImg from "../asserts/images/back.png";
import nextImg from "../asserts/images/next.png";

export default function Categories(props) {
  let categories = props.categories;
  /* ---------------------- scroll width and resize width --------------------- */
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });
  // console.log(dimensions);
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
    });
    // console.log(dimensions);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  // console.log(scrollWidth);
  useEffect(() => {
    // console.log("change width");
    // console.log(dimensions.width);
    // let scrollWidth =
    //   document.querySelector("#root").offsetWidth -
    //   document.querySelector("#root").clientWidth;
    // console.log(scrollWidth);
  }, [dimensions.width]);

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <img src={nextImg} alt="next" />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <img src={backImg} alt="back" />
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    infinite: true,
    lazyLoad: false,
    speed: 800,
    slidesToShow: dimensions.width < 1000 ? 1 : 3,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (current, next) => setImageIndex(next),
  };

  return (
    <section className="category-section">
      <Slider {...settings}>
        {categories.map((cat, id) => {
          return (
            <div
              key={id}
              className={
                id === imageIndex
                  ? "slide category-card activeSlide"
                  : "slide category-card"
              }
              onClick={() => {
                props.setCurrentView(cat.name);
              }}
            >
              <img className="category-card-img" src={cat.img[0].img} alt="" />
              <div className="camera-text-container">
                <div className="category-card-text">{cat.name}</div>
              </div>
            </div>
          );
        })}
        {/* {images.map((img, idx) => (
          <div className={idx === imageIndex ? "slide activeSlide" : "slide"}>
            <img src={img} alt={img} />
          </div>
        ))} */}
      </Slider>
    </section>
  );
}
