import Slider from "react-slick";
import backImg from "../asserts/images/back.png";
import nextImg from "../asserts/images/next.png";
import imgD from "../asserts/images/series/dog/DSC_0461.jpg";
import seriesImg1 from "../asserts/images/series/covid/MDF1.jpg";
import React, { useState } from "react";

export default function Series(props) {
  let { setCurrentView } = props;
  const [imageIndex, setImageIndex] = useState(0);

  let categories = [
    {
      name: "Murphy",
      info: "",
      img: imgD,
      view: "Murphy",
    },
    {
      name: "Million Daughter's Foundation, Covid and beyond",
      info: "",
      img: seriesImg1,
      view: "Million Daughter's Foundation, Covid and beyond",
    },
  ];

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <img src={nextImg} alt="next" />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <img src={backImg} alt="back" />
      </div>
    );
  };

  const settings = {
    infinite: true,
    lazyLoad: false,
    speed: 800,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (current, next) => setImageIndex(next),
  };

  return (
    <section className="category-section">
      <Slider {...settings}>
        {categories.map((cat, id) => {
          return (
            <div
              key={id}
              className={
                id === imageIndex
                  ? "slide category-card category-card-series activeSlide"
                  : "slide category-card category-card-series"
              }
              onClick={() => {
                setCurrentView(cat.view);
              }}
            >
              <img className="category-card-img" src={cat.img} alt="" />
              <div className="camera-text-container">
                <div className="category-card-text">{cat.name}</div>
              </div>
            </div>
          );
        })}
        {/* {images.map((img, idx) => (
          <div className={idx === imageIndex ? "slide activeSlide" : "slide"}>
            <img src={img} alt={img} />
          </div>
        ))} */}
      </Slider>
    </section>
  );
}
