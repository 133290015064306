import { useState, useEffect, useRef } from "react";

export default function About(props) {
  console.log("CAROUSEL RENDER ABOUT");
  //useMemo so that if same currentView value comes then it will use its last saved value directly without calling findCarousel func
  // let carouselImages = useMemo(() => findCarousel(currentView), [currentView]);
  let { carouselImages } = props;
  carouselImages = carouselImages.slice(1);

  const [carouselNumb, setCarouselNumb] = useState(0);

  const timeoutRef = useRef(null);
  const resetTimeout = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
  };

  // const nextCarouselHandler = () => {
  //   if (carouselNumb >= carouselImages.length - 1) {
  //     setCarouselNumb(0);
  //   } else {
  //     setCarouselNumb(carouselNumb + 1);
  //   }
  // };

  // const prevCarouselHandler = () => {
  //   if (carouselNumb === 0) {
  //     setCarouselNumb(carouselImages.length - 1);
  //   } else {
  //     setCarouselNumb(carouselNumb - 1);
  //   }
  // };

  // useEffect(() => {
  //   console.log("PAGE CHANGED");
  //   setCarouselNumb(0);
  // }, [carouselImages]);

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      // console.log("Timeout");
      setCarouselNumb((prev) =>
        prev === carouselImages.length - 1 ? 0 : prev + 1
      );
      return () => resetTimeout();
    }, 4000);
  }, [carouselNumb, carouselImages.length]);

  return (
    <section className="carousel-section about-carousel" id="about">
      <section className="about-section">
        <div>
          <h1 className="about-title">About the Photographer</h1>
          <p className="about-info">
            <strong>Siddhartha Rawat</strong> has been photographing for 6
            years. After 3 years of freelancing, he continued to formalize his
            skills towards his passion for photography by enrolling in a diploma
            course in Creative Photography at Raghu Rai Centre, Gurgaon.
            Coupling his love for depicting stories through photographs,
            Siddhartha has experienced that it is a different world for him
            altogether when he handles the camera.
          </p>
        </div>
      </section>
      <div className="carousel-container about-carousel-container">
        {/* <div
          className="prev-carousel-btn carousel-btn"
          onClick={() => prevCarouselHandler()}
        ></div>
        <div
          className="next-carousel-btn carousel-btn"
          onClick={() => nextCarouselHandler()}
        ></div> */}

        {carouselImages.map((item, index) => {
          return (
            <div
              key={index}
              className={
                index === carouselNumb
                  ? "carousel-img-wrapper active-carousel"
                  : "carousel-img-wrapper"
              }
            >
              <img
                className="carousel-img about-carousel-img"
                src={item.img}
                alt="carousel-img"
                key={index}
              />
            </div>
          );
        })}
      </div>
    </section>
  );
}
