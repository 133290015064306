import { useState } from "react";
import fbLogo from "../asserts/images/social/fb.png";
import instaLogo from "../asserts/images/social/insta.png";
import ytLogo from "../asserts/images/social/yt.png";
import linkedinLogo from "../asserts/images/social/linkedin.png";
import emailLogo from "../asserts/images/email.png";
import phoneLogo from "../asserts/images/phone.png";
import emailjs from "emailjs-com";

export default function Contact() {
  const [sendBtnMsg, setSendBtnMsg] = useState("Send");

  function submitForm(e) {
    setSendBtnMsg("Sending");
    e.preventDefault();
    const form_name = e.target[0].value;
    const form_email = e.target[1].value;
    const form_phone = e.target[2].value;
    const form_message = e.target[3].value;

    let obj = {
      form_name,
      form_email,
      form_phone,
      form_message,
    };
    console.log(obj);

    emailjs
      .send(
        "service_0i7zsh5",
        "template_b3o4boe",
        obj,
        "user_3WNdj8XTKrCjy5PNgNtJm"
      )
      .then((res) => {
        if (res.status === 200) {
          console.log("message sent!");
          setSendBtnMsg("Sent !");
        } else {
          console.log("error in sending message");
          setSendBtnMsg("Error !");
        }
      });
  }

  return (
    <section className="contact-section">
      {/* <h2 className="section-heading">Contact Heading</h2> */}
      <div className="contact-container">
        <div className="social-container">
          <div>
            <div className="social-icons">
              <h3>Socials</h3>
              <a href="https://www.facebook.com/1299951586728780/posts/4122507427806501">
                <img src={fbLogo} alt="social" />
              </a>
              <a href="https://www.instagram.com/yinyangpositives">
                <img src={instaLogo} alt="social" />
              </a>
              <a href="https://www.youtube.com/channel/UCtYtlglWxPNPjsalOozftFQ">
                <img src={ytLogo} alt="social" />
              </a>
              <a href="https://www.linkedin.com/in/siddhartha-rawat-26384218a">
                <img src={linkedinLogo} alt="social" />
              </a>
            </div>
            <div className="email-phone">
              <h3>Contact</h3>
              <div>
                <img src={emailLogo} alt="email" />
                yinyangnegatives@gmail.com
              </div>
              <div>
                <img src={phoneLogo} alt="phone" />
                8171357814 / 9456559818
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={submitForm}>
          <h3> Hire us</h3>
          <div>
            {/* <label htmlFor="name">YOUR NAME</label> */}
            <input
              type="text"
              name=""
              id="form-name"
              placeholder="YOUR NAME"
              required
            />
          </div>
          <div>
            {/* <label htmlFor="email">YOUR EMAIL</label> */}
            <input
              type="email"
              name=""
              id="form-email"
              placeholder="YOUR EMAIL"
              required
            />
          </div>
          <div>
            {/* <label htmlFor="number">YOUR NUMBER (OPTIONAL)</label> */}
            <input
              type="number"
              name=""
              id="form-number"
              placeholder="YOUR NUMBER (OPTIONAL)"
            />
          </div>
          <div>
            {/* <label htmlFor="message">YOUR MESSAGE</label> */}
            <textarea
              id="form-message"
              placeholder="YOUR MESSAGE"
              required
            ></textarea>
          </div>
          <button type="submit" id="send-btn">
            {sendBtnMsg}
          </button>
        </form>
      </div>
    </section>
  );
}
